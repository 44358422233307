export default {
  logo: 'k-管理平台',
  title: '用户登录',
  home: '首页',
  login: '登录',
  selectLanguage: '切换成功',
  themeColorChange: '主题色更换',
  usernameTip: '请输入用户名',
  passwordTip: '请输入密码',
  codeTip: '请输入验证码',
  usernameRule: '账号不能为空',
  passwordRule: '密码不能为空',
  passwordLengthRule: '密码至少为6位',
  codeRule: '验证码不能为空',
  codeLengthRule: '验证码至少为4位',
  components: '组件',
  confirm: '确认',
  cancel: '取消',
  closeOther: '关闭其他',
  closeAll: '关闭所有',
  close: '关闭',
  next: '下一个',
  prev: '上一个',
  guideTitle: '引导',
  guideDesc: '打开引导功能',
  hamburgerTitle: '汉堡按钮',
  hamburgerDesc: '打开和关闭左侧菜单',
  breadcrumbTitle: '面包屑',
  breadcrumbDesc: '指示当前页面位置',
  searchTitle: '搜索',
  searchDesc: '页面链接搜索',
  fullTitle: '全屏',
  fullDesc: '页面显示切换',
  themeTitle: '主题',
  themeDesc: '更换项目主题',
  langTitle: '国际化',
  langDesc: '语言切换',
  tagTitle: '标签',
  tagDesc: '已打开页面标签',
  sidebarTitle: '菜单',
  sidebarDesc: '项目功能菜单',
  count_to_page: '数字渐变',
  tables_page: '多功能表格',
  split_pane_page: '分割窗口',
  markdown_page: 'Markdown编辑器',
  editor_page: '富文本编辑器',
  icons_page: '自定义图标',
  img_cropper_page: '图片编辑器',
  update: '上传数据',
  join_page: 'QQ群',
  doc: '文档',
  update_table_page: '上传CSV文件',
  update_paste_page: '粘贴表格数据',
  multilevel: '多级菜单',
  directive_page: '指令',
  level_1: 'Level-1',
  level_2: 'Level-2',
  level_2_1: 'Level-2-1',
  level_2_3: 'Level-2-3',
  level_2_2: 'Level-2-2',
  level_2_2_1: 'Level-2-2-1',
  level_2_2_2: 'Level-2-2-2',
  excel: 'Excel',
  'upload-excel': '上传excel',
  'export-excel': '导出excel',
  tools_methods_page: '工具函数',
  drag_list_page: '拖拽列表',
  i18n_page: '多语言',
  modalTitle: '模态框题目',
  content: '这是模态框内容',
  buttonText: '显示模态框',
  'i18n-tip': '注：仅此页做了多语言，其他页面没有在多语言包中添加语言内容',
  error_store_page: '错误收集',
  error_logger_page: '错误日志',
  query: '带参路由',
  params: '动态路由',
  cropper_page: '图片裁剪',
  message_page: '消息中心',
  tree_table_page: '树状表格',
  org_tree_page: '组织结构树',
  drag_drawer_page: '可拖动抽屉',
  tree_select_page: '树状下拉选择器',
  tags_management: '标签管理',
  user: '系统管理',
  user_management: '用户管理',
  group_management: '机构管理',
  menu_management: '菜单管理',
  menu_options: '菜单选项',
  resources: '资源选项',
  role_management: '角色管理',
  role_list: '权限列表',
  jobInfo_management: '调度任务',
  area_management: '地区管理',
  category: '分类',
  category_management: '分类管理',
  categoryGrid_management: '宫格管理',
  specKey: '规格管理',
  specKey_management: '规格管理',
  specValue: '型号列表',
  spu: '商品Spu',
  spu_management: '商品管理',
  tag: '小标签',
  tag_management: '标签列表',
  sku: '商品Sku',
  sku_management: '商品Sku管理',
  banner: '广告banner',
  banner_management: 'banner列表',
  bannerItem: '广告banner相关的项目关联表',
  bannerItem_management: 'banner项目',
  segment: '首页标签',
  segment_management: '首页标签',
  activity: '活动',
  activity_management: '活动管理',
  coupon: '优惠券',
  coupon_management: '优惠券管理',
  integralLog: '日志相关',
  integralLog_management: '积分日志',
  appUser: 'app用户',
  appUser_management: 'app用户管理',
  order: '订单',
  order_management: '订单管理',
  earningLog: '收益',
  earningLog_management: '收益日志',
  awards: '奖池',
  awards_management: '奖池管理',
  theme: '主题',
  theme_management: '主题管理',
  integralSpu: '积分商品Spu',
  integralSpu_management: '积分商品'
}
