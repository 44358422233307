import Cookies from 'js-cookie'
// cookie保存的天数
import config from '@/config'
import { forEach, hasOneOf, objEqual } from '@/libs/tools'
import { ACCESS, REFRESH_TOKEN_KEY, TAG_VIEW, TOKEN_KEY } from '@/constant'

import { watch } from 'vue'
import store from '@/store'
import i18n from '@/i18n/index'

const { title, cookieExpires, useI18n } = config

export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: cookieExpires || 1 })
}

export const setRefreshToken = (refeshToken) => {
  Cookies.set(REFRESH_TOKEN_KEY, refeshToken, { expires: cookieExpires || 1 })
}

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY)
  if (token) return token
  else return false
}

export const getRefreshToken = () => {
  const refreshToken = Cookies.get(REFRESH_TOKEN_KEY)
  if (refreshToken) return refreshToken
  else return false
}

export const hasChild = (item) => {
  return item.children && item.children.length !== 0
}

/**
 * @param {Array} list 通过路由列表得到菜单列表
 * @returns {Array}
 */
export const getMenuByRouter = (list) => {
  const res = []
  forEach(list, (item) => {
    if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
      const obj = {
        path: item.path,
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta: item.meta
      }
      if (hasChild(item) || (item.meta && item.meta.showAlways)) {
        obj.children = getMenuByRouter(item.children)
      }
      if (item.meta && item.meta.href) obj.href = item.meta.href
      res.push(obj)
    }
  })
  return res
}

/**
 * @param {Array} routeMetched 当前路由metched
 * @returns {Array}
 */
export const getBreadCrumbList = (route, homeRoute) => {
  const homeItem = { ...homeRoute, icon: homeRoute.meta.icon }
  const routeMetched = route.matched
  if (routeMetched.some((item) => item.name === homeRoute.name)) {
    return [homeItem]
  }
  const res = routeMetched
    .filter((item) => {
      return item.meta === undefined || !item.meta.hideInBread
    })
    .map((item) => {
      const meta = { ...item.meta }
      if (meta.title && typeof meta.title === 'function') {
        meta.__titleIsFunction__ = true
        meta.title = meta.title(route)
      }
      const obj = {
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta: meta
      }
      return obj
    })
  // res = res.filter((item) => {
  //   return !item.meta.hideInMenu
  // })
  return [{ ...homeItem, to: homeRoute.path }, ...res]
}

export const getRouteTitleHandled = (route) => {
  const router = { ...route }
  const meta = { ...route.meta }
  let title = ''
  if (meta.title) {
    if (typeof meta.title === 'function') {
      meta.__titleIsFunction__ = true
      title = meta.title(router)
    } else title = meta.title
  }
  meta.title = title
  router.meta = meta
  return router
}
export const showTitle = (item) => {
  let { title, __titleIsFunction__ } = item.meta
  if (!title) return
  if (useI18n) {
    if (title.includes('{{') && title.includes('}}') && useI18n) {
      title = title.replace(/({{[\s\S]+?}})/, (m, str) =>
        str.replace(/{{([\s\S]*)}}/, (m, _) => i18n.global.t(_.trim()))
      )
    } else if (__titleIsFunction__) {
      title = item.meta.title
    } else {
      title = i18n.global.t(item.name)
    }
  } else {
    title = (item.meta && item.meta.title) || item.name
  }
  return title
}

/**
 * @description 本地存储和获取标签导航列表
 */
export const setTagNavListInLocalstorage = (list) => {
  localSave(TAG_VIEW, JSON.stringify(list))
}
/**
 * @returns {Array} 其中的每个元素只包含路由原信息中的name, path, meta三项
 */
export const getTagNavListFromLocalstorage = () => {
  const list = localRead(TAG_VIEW)
  return list ? JSON.parse(list) : []
}

/**
 * @param {Array} routers 路由列表数组
 * @description 用于找到路由列表中name为home的对象
 */
export const getHomeRoute = (routers, homeName = 'home') => {
  let i = -1
  const len = routers.length
  let homeRoute = {}
  while (++i < len) {
    const item = routers[i]
    if (item.children && item.children.length) {
      const res = getHomeRoute(item.children, homeName)
      if (res.name) return res
    } else {
      if (item.name === homeName) homeRoute = item
    }
  }
  return homeRoute
}

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute
  const newList = [...list]
  if (newList.findIndex((item) => item.name === name) >= 0) return newList
  else newList.push({ name, path, meta })
  return newList
}

/**
 * @param {*} access 用户权限数组，如 ['super_admin', 'admin']
 * @param {*} route 路由列表
 */
const hasAccess = (access, route) => {
  if (route.meta && route.meta.access) {
    return hasOneOf(access, route.meta.access)
  } else return true
}

/**
 * 权鉴
 * @param {*} name 即将跳转的路由name
 * @param {*} access 用户权限数组
 * @param {*} routes 路由列表
 * @description 用户是否可跳转到该页
 */
export const canTurnTo = (name, access, routes) => {
  const routePermissionJudge = (list) => {
    // eslint-disable-next-line array-callback-return
    return list.some((item) => {
      if (item.children && item.children.length) {
        return routePermissionJudge(item.children)
      } else if (item.name === name) {
        return hasAccess(access, item)
      }
    })
  }

  return routePermissionJudge(routes)
}

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = (url) => {
  const keyValueArr = url.split('?')[1].split('&')
  const paramObj = {}
  keyValueArr.forEach((item) => {
    const keyValue = item.split('=')
    paramObj[keyValue[0]] = keyValue[1]
  })
  return paramObj
}

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextRoute = (list, route) => {
  let res = {}
  if (list.length === 2) {
    res = getHomeRoute(list)
  } else {
    const index = list.findIndex((item) => routeEqual(item, route))
    if (index === list.length - 1) res = list[list.length - 2]
    else res = list[index + 1]
  }
  return res
}

/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
export const doCustomTimes = (times, callback) => {
  let i = -1
  while (++i < times) {
    callback(i)
  }
}

/**
 * @param {Object} file 从上传组件得到的文件对象
 * @returns {Promise} resolve参数是解析后的二维数组
 * @description 从Csv文件中解析出表格，解析成二维数组
 */
export const getArrayFromFile = (file) => {
  const nameSplit = file.name.split('.')
  const format = nameSplit[nameSplit.length - 1]
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file) // 以文本格式读取
    let arr = []
    reader.onload = function (evt) {
      const data = evt.target.result // 读到的数据
      const pasteData = data.trim()
      arr = pasteData
        .split(/[\n\u0085\u2028\u2029]|\r\n?/g)
        .map((row) => {
          return row.split('\t')
        })
        .map((item) => {
          return item[0].split(',')
        })
      if (format === 'csv') resolve(arr)
      else reject(new Error('[Format Error]:你上传的不是Csv文件'))
    }
  })
}

/**
 * @param {Array} array 表格数据二维数组
 * @returns {Object} { columns, tableData }
 * @description 从二维数组中获取表头和表格数据，将第一行作为表头，用于在iView的表格中展示数据
 */
export const getTableDataFromArray = (array) => {
  let columns = []
  let tableData = []
  if (array.length > 1) {
    const titles = array.shift()
    columns = titles.map((item) => {
      return {
        title: item,
        key: item
      }
    })
    tableData = array.map((item) => {
      const res = {}
      item.forEach((col, i) => {
        res[titles[i]] = col
      })
      return res
    })
  }
  return {
    columns,
    tableData
  }
}

export const findNodeUpper = (ele, tag) => {
  if (ele.parentNode) {
    if (ele.parentNode.tagName === tag.toUpperCase()) {
      return ele.parentNode
    } else {
      return findNodeUpper(ele.parentNode, tag)
    }
  }
}

export const findNodeUpperByClasses = (ele, classes) => {
  const parentNode = ele.parentNode
  if (parentNode) {
    const classList = parentNode.classList
    if (
      classList &&
      classes.every((className) => classList.contains(className))
    ) {
      return parentNode
    } else {
      return findNodeUpperByClasses(parentNode, classes)
    }
  }
}

export const findNodeDownward = (ele, tag) => {
  const tagName = tag.toUpperCase()
  if (ele.childNodes.length) {
    let i = -1
    const len = ele.childNodes.length
    // eslint-disable-next-line no-unreachable-loop
    while (++i < len) {
      const child = ele.childNodes[i]
      if (child.tagName === tagName) return child
      else return findNodeDownward(child, tag)
    }
  }
}

export const showByAccess = (access, canViewAccess) => {
  return hasOneOf(canViewAccess, access)
}

/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export const routeEqual = (route1, route2) => {
  const params1 = route1.params || {}
  const params2 = route2.params || {}
  const query1 = route1.query || {}
  const query2 = route2.query || {}
  return (
    route1.name === route2.name &&
    objEqual(params1, params2) &&
    objEqual(query1, query2)
  )
}

/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象
 */
export const routeHasExist = (tagNavList, routeItem) => {
  const len = tagNavList.length
  let res = false
  doCustomTimes(len, (index) => {
    if (routeEqual(tagNavList[index], routeItem)) res = true
  })
  return res
}

export const localSave = (key, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  localStorage.setItem(key, value)
}

export const localRead = (key) => {
  const value = localStorage.getItem(key)
  try {
    return JSON.parse(value)
  } catch (e) {
    return value || ''
  }
}

// scrollTop animation
export const scrollTop = (el, from = 0, to, duration = 500, endCallback) => {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame =
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        return window.setTimeout(callback, 1000 / 60)
      }
  }
  const difference = Math.abs(from - to)
  const step = Math.ceil((difference / duration) * 50)

  const scroll = (start, end, step) => {
    if (start === end) {
      endCallback && endCallback()
      return
    }

    let d = start + step > end ? end : start + step
    if (start > end) {
      d = start - step < end ? end : start - step
    }

    if (el === window) {
      window.scrollTo(d, d)
    } else {
      el.scrollTop = d
    }
    window.requestAnimationFrame(() => scroll(d, end, step))
  }
  scroll(from, to, step)
}

/**
 * @description 根据当前跳转的路由设置显示在浏览器标签的title
 * @param {Object} routeItem 路由对象
 * @param {Object} vm Vue实例
 */
export const setTitle = (routeItem) => {
  const handledRoute = getRouteTitleHandled(routeItem)
  const pageTitle = showTitle(handledRoute)
  const resTitle = pageTitle ? `${title} - ${pageTitle}` : title
  window.document.title = resTitle
}

/**
 * 对象过滤器 例如：{{SECTION_CHARGE | optionKV(section.charge)}}
 * @param object 例如：{CHARGE:{key:"C", value:"收费"},FREE:{key:"F", value:"免费"}}
 * @param key 例如：C
 * @param defaultValue 默认值
 * @returns {string} 例如：收费
 */
export const optionKV = (object, key, defaultValue) => {
  if (!defaultValue) {
    defaultValue = 'default'
  }
  if (!object || !key) {
    return defaultValue
  } else {
    let result = defaultValue
    for (const enums in object) {
      if (key === object[enums].key) {
        result = object[enums].value
      }
    }
    return result
  }
}
export const map2Array = (mapObject, append, isBefore) => {
  const result = []
  if (!mapObject) {
    return result
  }
  if (isBefore === undefined || isBefore) {
    if (append) {
      result.push(append)
    }
  }
  for (const enums in mapObject) {
    const key = mapObject[enums].key
    const value = mapObject[enums].value
    const obj = { key: key, value: value }
    result.push(obj)
  }
  if (isBefore !== undefined && !isBefore) {
    if (append) {
      result.push(append)
    }
  }
  return result
}
/**
 * 数组过滤器 例如：{{CHARGE | optionKVArray(section.charge)}}
 * @param list 例如：[{key:"C", value:"收费"},{key:"F", value:"免费"}]
 * @param key 例如：C
 * @param defaultValue 默认值
 * @returns {string} 例如：收费
 */
export const optionKVArray = (list, key, defaultValue) => {
  if (!defaultValue) {
    return ''
  }
  if (!list || !key) {
    return defaultValue
  } else {
    let result = defaultValue
    for (let i = 0; i < list.length; i++) {
      if (key === list[i].key) {
        result = list[i].value
      }
    }
    return result
  }
}

/**
 * 时长格式化
 * @param value 例如：36000
 * @returns {string} 例如：10:00:00
 */
export const formatSecond = (value) => {
  value = value || 0
  let second = parseInt(value, 10) // 秒
  let minute = 0 // 分
  let hour = 0 // 小时
  if (second >= 60) {
    // 当大于60秒时，才需要做转换
    minute = Math.floor(second / 60)
    second = Math.floor(second % 60)
    if (minute >= 60) {
      hour = Math.floor(minute / 60)
      minute = Math.floor(minute % 60)
    }
  } else {
    // 小于60秒时，直接显示，不需要处理
  }
  let result = '' + PrefixInteger(second, 2) + ''
  // 拼上分钟
  result = '' + PrefixInteger(minute, 2) + ':' + result
  // 拼上小时
  result = '' + PrefixInteger(hour, 2) + ':' + result
  return result
}

/**
 * 格式化指定长度，前面补0
 */
function PrefixInteger(num, length) {
  return (Array(length).join('0') + num).slice(-length)
}

/**
 * 格式化文件大小
 * @param value
 * @returns {string}
 */
export const formatFileSize = (value) => {
  value = value || 0
  let result
  if (value > 100 * 1024) {
    result = Math.round((value / 1024 / 1024) * 100) / 100 + 'MB'
  } else {
    result = Math.round((value / 1024) * 100) / 100 + 'KB'
  }
  return result
}
/** 数组扁平化 */
export const flatten = (arr) => {
  while (arr.some((item) => Array.isArray(item))) {
    arr = [].concat(...arr)
  }
  return arr
}

/** 获取routerName */
export const getRoutesName = (routes) => {
  const arr = []
  routes.forEach((item) => {
    arr.push(item.name)
    if (item.children && item.children.length > 0) {
      arr.push(getRoutesName(item.children))
    }
  })
  return flatten(arr)
}
/** 过滤出用户拥有的菜单 */
export const filterRoutes = (origin, target) => {
  const arr = []
  origin.forEach((item) => {
    if (target.includes(item.name)) {
      if (item.children && item.children.length > 0) {
        item.children = filterRoutes(item.children, target)
      }
      arr.push(item)
    }
  })
  return arr
}

/**
 * 获取登录用户信息
 */
export const getUserAccess = () => {
  return localRead(ACCESS) || []
}

/** 设置是否有权限 '' */
export const hasPermission = (perm) => {
  const roles = getUserAccess() || []
  return roles.includes(perm)
}

/** 多个权限点 ['1','2'] */
export const hasPermissions = (perms) => {
  const roles = getUserAccess() || []
  let flag = true
  for (const p of perms) {
    if (!roles.includes(p)) {
      flag = false
    }
  }
  return flag
}

/**
 * utc时间转换为GMT+8本地时间
 * @param {Date} inputTime
 * @returns 本地时间
 */
export const tzTime2LocalTime = (inputTime) => {
  if (!inputTime && typeof inputTime !== 'number') {
    return ''
  }
  let localTime = ''
  inputTime = new Date(inputTime).getTime()
  const offset = new Date().getTimezoneOffset()
  localTime = new Date(inputTime - offset * 60000).toISOString()
  localTime = localTime.substr(0, localTime.lastIndexOf('.'))
  localTime = localTime.replace('T', ' ')
  return localTime
}

/**
 *
 * @param {String} value 要省略的值
 * @param {Integer} maxLength 显示最大长度
 * @returns 替换省略号之后的值
 */
export const maxSlice = (value, maxLength) => {
  if (value) {
    if (
      !maxLength ||
      maxLength === null ||
      maxLength === 'null' ||
      maxLength === undefined ||
      maxLength === 'undefined'
    ) {
      maxLength = 5
    }
    return value.length > maxLength
      ? value.slice(0, maxLength) + '......'
      : value
  }
}

/**
 * 处理全局搜索国际化
 * @param  {...any} cbs 所有的回调
 */
export function watchSwitchLang(...cbs) {
  watch(
    () => store.getters['app/getLocal'],
    () => {
      cbs.forEach((cb) => cb(store.getters['app/getLocal']))
    },
    { immediate: true, deep: true }
  )
}
