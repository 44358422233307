import Dispatch from '@/libs/dipatch'

/** 管理系统管理用户表相关 */
export const userDispatch = new Dispatch({
  getUserList: '/sys/user/list',
  deleteUserById: '/sys/user/delete',
  checkUsername: '/sys/user/checkUsername',
  checkMobile: '/sys/user/checkMobile',
  checkEmail: '/sys/user/checkEmail',
  updateUserById: '/sys/user/update',
  addUser: '/register',
  updateUserBatchById: '/sys/user/batchUpdate',
  getUserRole: '/sys/user/getUserRole',
  updateUserRole: '/sys/user/updateUserRole',
  checkPassword: '/sys/user/checkPassword',
  export: '/sys/user/export',
  exportTemplate: '/sys/user/exportTemplate',
  importData: '/sys/user/importData',
  updatePassword: '/updatePassword',
  inforCard: '/inforCard'
})

/** 管理机构管理表相关 */
export const groupDispatch = new Dispatch({
  getGroupList: '/sys/group/list',
  queryGroupList: '/sys/group/queryGroupTreeList',
  getGroupDetail: '/sys/group/info',
  deleteGroupById: '/sys/group/delete',
  updateGroupById: '/sys/group/update',
  addGroup: '/sys/group/save',
  getGroupUser: '/sys/group/getGroupUser',
  updateGroupUser: '/sys/group/updateGroupUser',
  getGroupRole: '/sys/group/getGroupRole',
  updateGroupRole: '/sys/group/updateGroupRole'
})

/** 管理菜单管理表相关 */
export const menuDispatch = new Dispatch({
  deleteMenu: '/sys/menu/delete',
  check: '/sys/menu/check',
  updateMenuById: '/sys/menu/update',
  addMenu: '/sys/menu/save',
  getTreeList: '/sys/menu/tree',
  getCatalogList: '/sys/menu/getCatalog',
  transfer: '/sys/menu/transfer'
})

/** 管理角色管理表相关 */
export const roleDispatch = new Dispatch({
  getRoleList: '/sys/role/list',
  deleteRoleById: '/sys/role/delete',
  updateRoleById: '/sys/role/update',
  addRole: '/sys/role/save'
})

/** 管理角色管理表相关 */
export const fileDispatch = new Dispatch({
  uploadImg: '/uploadImg'
})

/** 管理调度任务信息相关 */
export const jobInfoDispatch = new Dispatch({
  getJobInfoList: '/sys/jobInfo/list',
  deleteJobInfoById: '/sys/jobInfo/delete',
  check: '/sys/jobInfo/check',
  updateJobInfoById: '/sys/jobInfo/update',
  addJobInfo: '/sys/jobInfo/save',
  updateJobInfoBatchById: '/sys/jobInfo/batchUpdate',
  export: '/sys/jobInfo/export',
  exportTemplate: '/sys/jobInfo/exportTemplate',
  importData: '/sys/jobInfo/importData',
  refreshJob: '/sys/jobInfo/refreshJob',
  refreshAll: '/sys/jobInfo/refreshAll',
  getJobLogList: '/sys/jobInfo/log',
  deleteJobLog: '/sys/jobInfo/deleteLog'
})

/** 管理地区表相关 */
export const areaDispatch = new Dispatch({
  getAreaList: '/sys/area/list',
  getAreaDetail: '/sys/area/info',
  deleteAreaById: '/sys/area/delete',
  check: '/sys/area/check',
  updateAreaById: '/sys/area/update',
  addArea: '/sys/area/save',
  queryAreaList: '/sys/area/queryAreaTreeList'
})

/** 管理分类相关 */
export const categoryDispatch = new Dispatch({
  getCategoryList: '/category/category/list',
  deleteCategoryById: '/category/category/delete',
  check: '/category/category/check',
  getCategoryDetail: '/category/category/info',
  updateCategoryById: '/category/category/update',
  addCategory: '/category/category/save',
  updateCategoryBatchById: '/category/category/batchUpdate',
  getLastNode: '/category/category/last'
})

/** 管理小程序首页展示宫格相关 */
export const categoryGridDispatch = new Dispatch({
  getCategoryGridList: '/category/categoryGrid/list',
  deleteCategoryGridById: '/category/categoryGrid/delete',
  check: '/category/categoryGrid/check',
  updateCategoryGridById: '/category/categoryGrid/update',
  addCategoryGrid: '/category/categoryGrid/save',
  updateCategoryGridBatchById: '/category/categoryGrid/batchUpdate',
  export: '/category/categoryGrid/export',
  exportTemplate: '/category/categoryGrid/exportTemplate',
  importData: '/category/categoryGrid/importData'
})

/** 管理商品规格表相关 */
export const specKeyDispatch = new Dispatch({
  getSpecKeyList: '/spec/specKey/list',
  deleteSpecKeyById: '/spec/specKey/delete',
  check: '/spec/specKey/check',
  updateSpecKeyById: '/spec/specKey/update',
  addSpecKey: '/spec/specKey/save'
})

/** 管理商品规格型号表相关 */
export const specValueDispatch = new Dispatch({
  getSpecValueList: '/spec/specValue/list',
  deleteSpecValueById: '/spec/specValue/delete',
  check: '/spec/specValue/check',
  updateSpecValueById: '/spec/specValue/update',
  addSpecValue: '/spec/specValue/save'
})

/** 管理商品Spu相关 */
export const spuDispatch = new Dispatch({
  getSpuList: '/spu/spu/list',
  deleteSpuById: '/spu/spu/delete',
  deleteSegmentSpu: '/spu/spu/deleteSegmentSpu',
  check: '/spu/spu/check',
  updateSpuById: '/spu/spu/update',
  addSpu: '/spu/spu/save',
  updateSpuBatchById: '/spu/spu/batchUpdate',
  export: '/spu/spu/export',
  getSpecValue: '/spu/spu/getSpecValue',
  checkSpecValue: '/spu/spu/checkSpecValue',
  getSegmentSpuList: '/spu/spu/getSegmentSpuList'
})

/** 管理小标签相关 */
export const tagDispatch = new Dispatch({
  getTagList: '/tag/tag/list',
  deleteTagById: '/tag/tag/delete',
  check: '/tag/tag/check',
  updateTagById: '/tag/tag/update',
  addTag: '/tag/tag/save',
  updateTagBatchById: '/tag/tag/batchUpdate',
  export: '/tag/tag/export',
  exportTemplate: '/tag/tag/exportTemplate',
  importData: '/tag/tag/importData'
})

/** 管理商品Sku相关 */
export const skuDispatch = new Dispatch({
  getSkuList: '/spu/sku/list',
  deleteSkuById: '/spu/sku/delete',
  check: '/spu/sku/check',
  updateSkuById: '/spu/sku/update',
  addSku: '/spu/sku/save',
  updateSkuBatchById: '/spu/sku/batchUpdate',
  export: '/spu/sku/export',
  exportTemplate: '/spu/sku/exportTemplate',
  importData: '/spu/sku/importData'
})

/** 管理广告banner相关 */
export const bannerDispatch = new Dispatch({
  getBannerList: '/banner/banner/list',
  deleteBannerById: '/banner/banner/delete',
  check: '/banner/banner/check',
  updateBannerById: '/banner/banner/update',
  addBanner: '/banner/banner/save',
  updateBannerBatchById: '/banner/banner/batchUpdate',
  export: '/banner/banner/export',
  exportTemplate: '/banner/banner/exportTemplate',
  importData: '/banner/banner/importData'
})

/** 管理广告banner相关的项目关联表相关 */
export const bannerItemDispatch = new Dispatch({
  getBannerItemList: '/banner/bannerItem/list',
  deleteBannerItemById: '/banner/bannerItem/delete',
  check: '/banner/bannerItem/check',
  updateBannerItemById: '/banner/bannerItem/update',
  addBannerItem: '/banner/bannerItem/save',
  updateBannerItemBatchById: '/banner/bannerItem/batchUpdate',
  export: '/banner/bannerItem/export',
  exportTemplate: '/banner/bannerItem/exportTemplate',
  importData: '/banner/bannerItem/importData'
})

/** 管理首页标签相关 */
export const segmentDispatch = new Dispatch({
  getSegmentList: '/segment/segment/list',
  deleteSegmentById: '/segment/segment/delete',
  check: '/segment/segment/check',
  updateSegmentById: '/segment/segment/update',
  addSegment: '/segment/segment/save',
  updateSegmentBatchById: '/segment/segment/batchUpdate',
  export: '/segment/segment/export',
  exportTemplate: '/segment/segment/exportTemplate',
  importData: '/segment/segment/importData'
})

/** 管理活动相关 */
export const activityDispatch = new Dispatch({
  getActivityList: '/activity/activity/list',
  deleteActivityById: '/activity/activity/delete',
  check: '/activity/activity/check',
  updateActivityById: '/activity/activity/update',
  addActivity: '/activity/activity/save',
  updateActivityBatchById: '/activity/activity/batchUpdate',
  export: '/activity/activity/export',
  exportTemplate: '/activity/activity/exportTemplate',
  importData: '/activity/activity/importData',
  getActivitySpuList: '/activity/activity/getActivitySpuList',
  getActivityCategoryList: '/activity/activity/getActivityCategoryList',
  getActivityCouponList: '/activity/activity/getActivityCouponList'
})

/** 管理优惠券相关 */
export const couponDispatch = new Dispatch({
  getCouponList: '/coupon/coupon/list',
  deleteCouponById: '/coupon/coupon/delete',
  check: '/coupon/coupon/check',
  updateCouponById: '/coupon/coupon/update',
  addCoupon: '/coupon/coupon/save',
  updateCouponBatchById: '/coupon/coupon/batchUpdate',
  export: '/coupon/coupon/export',
  exportTemplate: '/coupon/coupon/exportTemplate',
  importData: '/coupon/coupon/importData',
  getCouponCategoryList: '/coupon/coupon/getCouponCategoryList'
})

/** 管理相关 */
export const integralLogDispatch = new Dispatch({
  getIntegralLogList: '/integral/integralLog/list',
  deleteIntegralLogById: '/integral/integralLog/delete',
  check: '/integral/integralLog/check',
  updateIntegralLogById: '/integral/integralLog/update',
  addIntegralLog: '/integral/integralLog/save',
  updateIntegralLogBatchById: '/integral/integralLog/batchUpdate',
  export: '/integral/integralLog/export',
  exportTemplate: '/integral/integralLog/exportTemplate',
  importData: '/integral/integralLog/importData'
})

/** 管理相关 */
export const appUserDispatch = new Dispatch({
  getAppUserList: '/user/appUser/list',
  deleteAppUserById: '/user/appUser/delete',
  check: '/user/appUser/check',
  updateAppUserById: '/user/appUser/update',
  addAppUser: '/user/appUser/save',
  updateAppUserBatchById: '/user/appUser/batchUpdate',
  export: '/user/appUser/export',
  exportTemplate: '/user/appUser/exportTemplate',
  importData: '/user/appUser/importData'
})

/** 管理订单相关 */
export const orderDispatch = new Dispatch({
  getOrderList: '/order/order/list',
  deleteOrderById: '/order/order/delete',
  check: '/order/order/check',
  updateOrderById: '/order/order/update',
  addOrder: '/order/order/save',
  updateOrderBatchById: '/order/order/batchUpdate',
  export: '/order/order/export',
  exportTemplate: '/order/order/exportTemplate',
  importData: '/order/order/importData'
})

/** 管理相关 */
export const earningLogDispatch = new Dispatch({
  getEarningLogList: '/earning/earningLog/list',
  deleteEarningLogById: '/earning/earningLog/delete',
  check: '/earning/earningLog/check',
  updateEarningLogById: '/earning/earningLog/update',
  addEarningLog: '/earning/earningLog/save',
  updateEarningLogBatchById: '/earning/earningLog/batchUpdate',
  export: '/earning/earningLog/export',
  exportTemplate: '/earning/earningLog/exportTemplate',
  importData: '/earning/earningLog/importData'
})

/** 管理奖池相关 */
export const awardsDispatch = new Dispatch({
  getAwardsList: '/award/awards/list',
  deleteAwardsById: '/award/awards/delete',
  check: '/award/awards/check',
  updateAwardsById: '/award/awards/update',
  addAwards: '/award/awards/save',
  updateAwardsBatchById: '/award/awards/batchUpdate',
  export: '/award/awards/export',
  exportTemplate: '/award/awards/exportTemplate',
  importData: '/award/awards/importData'
})

/** 管理主题相关 */
export const themeDispatch = new Dispatch({
  getThemeList: '/theme/theme/list',
  deleteThemeById: '/theme/theme/delete',
  check: '/theme/theme/check',
  updateThemeById: '/theme/theme/update',
  addTheme: '/theme/theme/save',
  updateThemeBatchById: '/theme/theme/batchUpdate',
  export: '/theme/theme/export',
  getThemeSpuList: '/theme/theme/getThemeSpuList'
})

/** 管理积分商品Spu相关 */
export const integralSpuDispatch = new Dispatch({
  getIntegralSpuList: '/spu/integralSpu/list',
  deleteIntegralSpuById: '/spu/integralSpu/delete',
  check: '/spu/integralSpu/check',
  updateIntegralSpuById: '/spu/integralSpu/update',
  addIntegralSpu: '/spu/integralSpu/save',
  updateIntegralSpuBatchById: '/spu/integralSpu/batchUpdate',
  export: '/spu/integralSpu/export',
  exportTemplate: '/spu/integralSpu/exportTemplate',
  importData: '/spu/integralSpu/importData'
})
