export default {
  logo: 'k-管理平台',
  title: '用戶登錄',
  home: '首頁',
  login: '登錄',
  selectLanguage: '切換成功',
  themeColorChange: '主題色更換',
  usernameTip: '請輸入用戶名',
  passwordTip: '請輸入密碼',
  codeTip: '請輸入驗證碼',
  usernameRule: '賬號不能為空',
  passwordRule: '密碼不能為空',
  passwordLengthRule: '密碼至少為6位',
  codeRule: '驗證碼不能為空',
  codeLengthRule: '驗證碼至少為4位',
  components: '组件',
  confirm: '確認',
  cancel: '取消',
  closeOther: '關閉其他',
  closeAll: '關閉所有',
  close: '關閉',
  next: '下一個',
  prev: '上一個',
  guideTitle: '引導',
  guideDesc: '打開引導功能',
  hamburgerTitle: '漢堡按鈕',
  hamburgerDesc: '打開和關閉左側菜單',
  breadcrumbTitle: '麵包屑',
  breadcrumbDesc: '指示當前頁面位置',
  searchTitle: '搜索',
  searchDesc: '頁面鏈接搜索',
  fullTitle: '全屏',
  fullDesc: '頁面顯示切換',
  themeTitle: '主題',
  themeDesc: '更換項目主題',
  langTitle: '國際化',
  langDesc: '語言切換',
  tagTitle: '標籤',
  tagDesc: '已打開頁面標籤',
  sidebarTitle: '菜單',
  sidebarDesc: '項目功能菜單',
  count_to_page: '数字渐变',
  tables_page: '多功能表格',
  split_pane_page: '分割窗口',
  markdown_page: 'Markdown編輯器',
  editor_page: '富文本編輯器',
  icons_page: '自定義圖標',
  img_cropper_page: '圖片編輯器',
  update: '上傳數據',
  join_page: 'QQ群',
  doc: '文檔',
  update_table_page: '上傳CSV文件',
  update_paste_page: '粘貼表格數據',
  multilevel: '多级菜单',
  directive_page: '指令',
  level_1: 'Level-1',
  level_2: 'Level-2',
  level_2_1: 'Level-2-1',
  level_2_3: 'Level-2-3',
  level_2_2: 'Level-2-2',
  level_2_2_1: 'Level-2-2-1',
  level_2_2_2: 'Level-2-2-2',
  excel: 'Excel',
  'upload-excel': '上傳excel',
  'export-excel': '導出excel',
  tools_methods_page: '工具函數',
  drag_list_page: '拖拽列表',
  i18n_page: '多語言',
  modalTitle: '模態框題目',
  content: '這是模態框內容',
  buttonText: '顯示模態框',
  'i18n-tip': '注：僅此頁做了多語言，其他頁面沒有在多語言包中添加語言內容',
  error_store_page: '錯誤收集',
  error_logger_page: '錯誤日誌',
  query: '帶參路由',
  params: '動態路由',
  cropper_page: '圖片裁剪',
  message_page: '消息中心',
  tree_table_page: '樹狀表格',
  org_tree_page: '組織結構樹',
  drag_drawer_page: '可拖動抽屜',
  tree_select_page: '樹狀下拉選擇器',
  tags_management: '標籤管理',
  user: '系統管理',
  user_management: '用戶管理',
  group_management: '機構管理',
  menu_management: '菜單管理',
  menu_options: '菜單選項',
  resources: '資源選項',
  role_management: '角色管理',
  role_list: '權限列表',
  jobInfo_management: '調度任務',
  area_management: '地區管理',
  category: '分類',
  category_management: '分類管理',
  categoryGrid_management: '宮格管理',
  specKey: '規格管理',
  specKey_management: '規格管理',
  specValue: '型號列表',
  spu: '商品Spu',
  spu_management: '商品管理',
  tag: '小標籤',
  tag_management: '小標籤管理',
  sku: '商品Sku',
  sku_management: '商品Sku管理',
  banner: '廣告banner',
  banner_management: 'banner列表',
  bannerItem: '廣告banner相關的項目關聯表',
  bannerItem_management: '廣告banner相關的項目關聯表管理',
  segment: '首頁標籤',
  segment_management: '首頁標籤',
  activity: '活動',
  activity_management: '活動管理',
  coupon: '優惠券',
  coupon_management: '優惠券管理',
  integralLog: '日誌相關',
  integralLog_management: '積分日誌',
  appUser: 'APP用戶',
  appUser_management: 'APP用戶管理',
  order: '訂單',
  order_management: '訂單管理',
  earningLog: '收益',
  earningLog_management: '收益日誌',
  awards: '獎池',
  awards_management: '獎池管理',
  theme: '主題',
  theme_management: '主題管理',
  integralSpu: '積分商品Spu',
  integralSpu_management: '積分商品'
}
