<template>
  <div class="navbar">
    <hamburger ref="hamburger" class="hamburger-container" />
    <breadcrumb id="guide-breadcrumb" class="breadcrumb-container" />
    <div class="right-menu">
      <!-- 功能引导 -->
      <guide class="right-menu-item hover-effect"></guide>
      <!-- 搜索 -->
      <header-search class="right-menu-item hover-effect"></header-search>
      <!-- 全屏 -->
      <screenfull class="right-menu-item hover-effect" />
      <!-- 主題色选择器 -->
      <theme-picker class="right-menu-item hover-effect"></theme-picker>
      <!-- 语言选择器 -->
      <lang-select class="right-menu-item hover-effect" />
      <!-- 头像 -->
      <Dropdown class="avatar-container" trigger="click" @on-click="onClick">
        <div class="avatar-wrapper">
          <Avatar
            shape="square"
            :size="40"
            :src="$store.getters['user/getUserDetail'].avatar"
          ></Avatar>
          <Icon type="md-settings" />
        </div>
        <template #list>
          <DropdownMenu class="user-dropdown">
            <router-link to="/">
              <DropdownItem> 首页 </DropdownItem>
            </router-link>
            <a target="_blank" href="https://www.creamk.com/">
              <DropdownItem>作者首页</DropdownItem>
            </a>
            <DropdownItem name="update_password"> 修改密码 </DropdownItem>
            <DropdownItem divided @click="handleLogout">
              退出登录
            </DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
    <UpdatePasswordModel :passwordOpt="passwordOpt"> </UpdatePasswordModel>
  </div>
</template>

<script setup>
import Guide from '@/components/Guide'
import HeaderSearch from '@/components/HeaderSearch'
import Screenfull from '@/components/Screenfull'
import LangSelect from '@/components/LangSelect'
import ThemePicker from '@/components/ThemeSelect'
import store from '@/store'
import router from '@/router'
import Hamburger from '@/components/Hamburger'
import Breadcrumb from '@/components/Breadcrumb/index'
import { Spin } from 'view-ui-plus'
import UpdatePasswordModel from '@/components/tables/update-password'
import { ref } from '@vue/reactivity'
const handleLogout = () => {
  store.dispatch('user/handleLogOut').then((res) => {
    // store.commit('app/setTagNavList', [])
    Spin.show()
    document.location.reload()
    setTimeout(() => {
      router.push({ name: 'login' })
      Spin.hide()
    }, 500)
  })
}
const passwordOpt = ref({})
const onClick = (name) => {
  // 修改密码
  if (name === 'update_password') {
    passwordOpt.value.isShow = true
    passwordOpt.value.handleLogout = handleLogout
  }
}
</script>
<style lang="scss" scoped>
.navbar {
  height: 50px;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: all 0.5s;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    display: flex;
    align-items: center;
    float: right;
    padding-right: 16px;

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .ivu-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
      }
    }
  }
}
</style>
