export default [
  {
    path: '/user',
    name: 'user',
    meta: {
      icon: 'md-albums',
      title: '系统管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'group',
        name: 'group_management',
        meta: {
          icon: 'ios-people',
          title: '机构管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/group/index')
      },
      {
        path: 'user',
        name: 'user_management',
        meta: {
          icon: 'ios-person',
          title: '用户管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/user/index')
      },
      {
        path: 'menu',
        name: 'menu_management',
        meta: {
          icon: 'ios-grid',
          title: '菜单管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/menu/index')
      },
      {
        path: 'role',
        name: 'role_management',
        meta: {
          icon: 'ios-bowtie',
          title: '角色管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/role/index')
      },
      {
        path: 'area',
        name: 'area_management',
        meta: {
          icon: 'ios-jet',
          title: '地区表管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/area/index')
      },
      {
        path: 'jobInfo',
        name: 'jobInfo_management',
        meta: {
          icon: 'ios-clock',
          title: '调度任务',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/jobInfo/index')
      }
    ]
  },
  {
    path: '/coupon',
    name: 'coupon',
    meta: {
      icon: 'md-albums',
      title: '优惠券管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'coupon_management',
        meta: {
          icon: 'md-card',
          title: '优惠券管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/coupon/index')
      }
    ]
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {
      icon: 'md-albums',
      title: '活动管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'activity_management',
        meta: {
          icon: 'ios-flame',
          title: '活动管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/activity/index')
      }
    ]
  },
  {
    path: '/theme',
    name: 'theme',
    meta: {
      icon: 'md-albums',
      title: '主题管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'theme_management',
        meta: {
          icon: 'logo-buffer',
          title: '主题管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/theme/index')
      }
    ]
  },
  {
    path: '/banner',
    name: 'banner',
    meta: {
      icon: 'md-albums',
      title: '广告banner管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'banner_management',
        meta: {
          icon: 'md-radio',
          title: '广告banner管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/banner/index')
      }
    ]
  },
  {
    path: '/category',
    name: 'category',
    meta: {
      icon: 'md-albums',
      title: '分类管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'category',
        name: 'category_management',
        meta: {
          icon: 'ios-people',
          title: '分类管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/category/index')
      },
      {
        path: 'categoryGrid',
        name: 'categoryGrid_management',
        meta: {
          icon: 'ios-people',
          title: '小程序首页展示宫格管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/categoryGrid/index')
      }
    ]
  },
  {
    path: '/specKey',
    name: 'specKey',
    meta: {
      icon: 'md-albums',
      title: '商品规格表管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'specKey',
        name: 'specKey_management',
        meta: {
          icon: 'ios-keypad',
          title: '商品规格表管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/specKey/index')
      }
    ]
  },
  {
    path: '/segment',
    name: 'segment',
    meta: {
      icon: 'md-albums',
      title: '首页标签管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'segment_management',
        meta: {
          icon: 'ios-bookmark',
          title: '首页标签管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/segment/index')
      }
    ]
  },
  {
    path: '/spu',
    name: 'spu',
    meta: {
      icon: 'md-albums',
      title: '商品管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'tag',
        name: 'tag_management',
        meta: {
          icon: 'ios-bookmarks',
          title: '标签列表',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/tag/index')
      },
      {
        path: 'spu',
        name: 'spu_management',
        meta: {
          icon: 'md-basket',
          title: '商品管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/spu/index')
      },
      {
        path: 'integralSpu',
        name: 'integralSpu_management',
        meta: {
          icon: 'ios-people',
          title: '积分商品Spu管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/integralSpu/index')
      },
      {
        path: 'sku',
        name: 'sku_management',
        meta: {
          icon: 'ios-people',
          title: '商品Sku管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: true
        },
        component: () => import('@/views/sku/index')
      }
    ]
  },
  {
    path: '/integralLog',
    name: 'integralLog',
    meta: {
      icon: 'md-albums',
      title: '管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'integralLog',
        name: 'integralLog_management',
        meta: {
          icon: 'md-filing',
          title: '管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/integralLog/index')
      },
      {
        path: 'earningLog',
        name: 'earningLog_management',
        meta: {
          icon: 'logo-usd',
          title: '管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/earningLog/index')
      }
    ]
  },
  {
    path: '/appUser',
    name: 'appUser',
    meta: {
      icon: 'md-albums',
      title: '管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'appUser_management',
        meta: {
          icon: 'ios-person',
          title: '管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/appUser/index')
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      icon: 'md-albums',
      title: '订单管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'order_management',
        meta: {
          icon: 'md-list-box',
          title: '订单管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/order/index')
      }
    ]
  },
  {
    path: '/awards',
    name: 'awards',
    meta: {
      icon: 'md-albums',
      title: '奖池管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'awards_management',
        meta: {
          icon: 'ios-trophy',
          title: '奖池管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/awards/index')
      }
    ]
  }
]
